import React from 'react';

import Login from '../components/Form/Login';
import Base from '../layout/base';

function App() {
  return (
    <Base>
      <div className="App h-screen flex items-center justify-center">
        <Login />
      </div>
    </Base>
  );
}

export default App;
