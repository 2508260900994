import React, { useContext, useState } from "react";
import { GlobalContext } from "../../context/GlobalState";
import OverlayFrame from "../OverlayFrame";



export default function Login({

}) {

    const { setBaseInfo, baseInfo } = useContext(GlobalContext);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    return (
        <OverlayFrame>
            <h2 className="mb-6 text-center text-xl font-extrabold text-white">Partner-Login</h2>

            <div
                className="space-y-6"
                action="/registrieren"
                method="POST"
            >
                <div>
                    <label htmlFor="email" className="block text-sm font-medium text-white">
                        E-Mail
                    </label>
                    <div className="mt-1">
                        <input
                            id="email"
                            name="email"
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            placeholder="hans@muster.ch"
                            autoComplete="email"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-theme_orange focus:border-theme_orange sm:text-sm"
                        />
                    </div>
                </div>

                <div>
                    <label htmlFor="password" className="block text-sm font-medium text-white">
                        Kennwort
                    </label>
                    <div className="mt-1">
                        <input
                            id="password"
                            placeholder="********"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-theme_orange focus:border-theme_orange sm:text-sm"
                        />
                    </div>
                </div>

                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <input
                            id="remember-me"
                            name="remember-me"
                            type="checkbox"
                            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300"
                        />
                        <label htmlFor="remember-me" className="ml-2 block text-sm text-white">
                            Angemeldet bleiben
                        </label>
                    </div>

                    <div className="text-sm">
                        <a href="#" className="font-medium text-gray-200 hover:text-indigo-500">
                            Passwort vergessen?
                        </a>
                    </div>
                </div>

                <div>
                    <a href="/projekte" title="Registration vervollständigen">
                        <button
                            type="submit"
                            className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium transition-color text-white bg-theme_green hover:bg-theme_orange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={(e) => {
                                setBaseInfo({
                                    email: email,
                                    password: password
                                })
                                // window.location.href = '/registrieren'; //relative to domain

                            }}
                        >
                            Anmelden
                        </button>
                    </a>
                </div>
            </div>


        </OverlayFrame>
    )
}